'use strict'
import { TimeFormat } from '@/utils/utilities'
import dayjs from 'dayjs'

const MonitoringListEntity = data => {
    data = data || {}

    let last_update = data.last_update ? dayjs(data.last_update).format('YYYY-MM-DD hh:mm A') : '-'
    let latest_update_status = data.latest_update_status || {}
    // let last_update = latest_update_status.updated_at ? dayjs(latest_update_status.updated_at).format('YYYY-MM-DD hh:mm A') : '-'

    let totalOpt = data.total_opt || {}
    let totalOptTime = totalOpt.total_opt_time || 0
    let totalOptMil = totalOpt.total_opt_mil || 0

    totalOptTime = TimeFormat.secToHour(totalOptTime)
    totalOptMil = Math.ceil(totalOptMil / 1000)

    let formatter = Intl.NumberFormat('id-ID')

    return {
        vehicle_id: data.vehicle_id,
        vehicle_name: data.vehicle_name || data.device_name || '-',
        imei: data.imei,
        vin: data.vin || '-',
        device_name: data.device_name || '-',
        thumb: data.vehicle_thumb,
        last_update,
        longitude: data.longitude || 0.0,
        latitude: data.latitude || 0.0,
        latest_update_status: latest_update_status,
        status: data.operate_status || false,
        total_opt_time: `${formatter.format(totalOptTime.h)}h ${totalOptTime.m}m`,
        total_opt_mil: `${formatter.format(totalOptMil)} km`
    }
}


const DetailEntity = data => {
    data = data || {}

    let latest_update_status = data.latest_update_status || {}

    let totalOpt = data.total_opt || {}
    let totalOptTime = totalOpt.total_opt_time || 0
    let totalOptMil = totalOpt.total_opt_mil || 0

    totalOptTime = TimeFormat.secToHour(totalOptTime)
    totalOptMil = Math.ceil(totalOptMil / 1000)

    let formatter = Intl.NumberFormat('id-ID')

    return {
        vehicle_id: data.vehicle_id,
        vehicle_name: data.vehicle_name || data.device_name || '-',
        imei: data.imei,
        vin: data.vin || '-',
        device_name: data.device_name || '-',
        thumb: data.vehicle_thumb,
        last_update: latest_update_status.updated_at,
        longitude: data.longitude || 0.0,
        latitude: data.latitude || 0.0,
        latest_update_status: latest_update_status,
        status: data.operate_status || false,
        total_opt_time: `${formatter.format(totalOptTime.h)}h ${totalOptTime.m}m`,
        total_opt_mil: `${formatter.format(totalOptMil)} km`
    }
}

const DetailStatEntity = data => {
    data = data || {}

    let maxOptTime = TimeFormat.secToHour(data.max_opt_time)
    let minOptTime = TimeFormat.secToHour(data.min_opt_time)

    let maxOptMil = Number(data.max_opt_mil) || 0
    maxOptMil = Math.ceil(maxOptMil / 1000)

    let minOptMil = Number(data.min_opt_mil) || 0
    minOptMil = Math.ceil(minOptMil / 1000)

    let avgOptTime = Number(data.avg_opt_time) || 0
    avgOptTime = Math.ceil(avgOptTime / 1000)

    let avgOptMil = Number(data.avg_opt_mil) || 0
    avgOptMil = Math.ceil(avgOptMil / 1000)

    let period = dayjs().format('MMMM')

    return {
        period,
        max_opt_time: maxOptTime.h || 0,
        min_opt_time: minOptTime.h || 0,
        max_opt_mil: maxOptMil,
        min_opt_mil: minOptMil,
        daily_avg_opt_time: avgOptTime,
        daily_avg_opt_mil: avgOptMil
    }
}


const LogSatelliteEntity = data => {
    data = data || {}

    const date = data.timestamp ? dayjs(data.timestamp).format('DD/MM/YYYY') : ''
    const opt_time = TimeFormat.secToHour(data.operate_time)
    let opt_mil = 0
    if (data.operate_mileage) {
        opt_mil = Number((data.operate_mileage / 1000).toFixed(2))
    }
    let converter_opt = 0
    if (data.max_operate_time_minutes) {
        const hours = Math.floor(data.max_operate_time_minutes / 60);
        const minutes = data.max_operate_time_minutes % 60;

        converter_opt = `${hours}h ${minutes}m`;
    }


    return {
        timestamp: data.timestamp,
        lat: data.latitude,
        lng: data.longitude,
        operate_time: data.operate_time,
        operate_mileage: data.operate_mileage,
        max_operate_time_minutes: data.max_operate_time_minutes,
        opt_time_v2: converter_opt,
        date,
        opt_time: `${opt_time.h}h ${opt_time.m}m`,
        opt_mil: `${opt_mil} km`
    }
}

export default {
    MonitoringListEntity,
    DetailEntity,
    DetailStatEntity,
    LogSatelliteEntity
}