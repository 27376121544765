<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 520px;max-width: 95%;margin-top: 15px;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
              loader(:loading="saving")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold {{ vehicle ? 'Ubah Kendaraan' : 'Tambahkan Kendaraan' }}
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                .py-4.my-2.px-4
                  button.btn.btn-block.btn-lg.btn-outline-success.d-flex.align-items-center.justify-content-center.mb-4(@click="selectPhoto = true" v-if="!!image") 
                    div.mr-2.h4.m-0
                      check
                    | A photo has been selected
                  button.btn.btn-block.btn-lg.btn-outline-primary.d-flex.align-items-center.justify-content-center.mb-4(@click="selectPhoto = true" v-if="!image") 
                    div.mr-2.h4.m-0
                      camera
                    | {{ !vehicle ? 'Tambahkan Foto Kendaraan' : 'Ubah Foto Kendaraan' }}
                  .form-group
                    label Nama Kendaraan
                    input.form-control(v-model="vehicle_name" placeholder="Nama Kendaraan")
                  .form-group
                    label Nomor Polisi
                    input.form-control(v-model="vin" placeholder="Nomor Polisi")
                  .form-group
                    label Nama Perangkat
                    b-form-select(:options="optionDevices" v-model="device_id")
                  .form-group
                    label Jenis Kendaraan
                    .row
                      .col
                        b-form-select(:options="optionTypes" v-model="vehicle_type")
                      .col-auto.pl-0
                        button.btn.btn-outline-primary(@click="showTypeModal = true" style="padding: 1.5px 10px")
                          .h3.m-0
                            plus(:size="64")
                  .form-group
                    label Seri
                    .row
                      .col
                        b-form-select(
                          :options="optionSeries" 
                          v-model="series" 
                          :disabled="optionSeries.length == 0")
                      .col-auto.pl-0
                        button.btn.btn-outline-primary(@click="OnAddSeries(vehicle_type)" style="padding: 1.5px 10px")
                          .h3.m-0
                            plus(:size="64")
                  .form-group
                    label.d-flex.justify-content-between.align-items-center
                        span Lebar Wiper
                        span.text-muted (in cm)
                    input.form-control(v-model="wiper_width" maxlength=2 placeholder="Centimeter / Cm")
                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.border-0.bg-white.py-1.px-5.text-danger(style="text-transform: none;" @click="onCancel()") Batal
                  button.px-4.btn-sm.btn.btn-primary(style="text-transform: none;" v-if="!vehicle" @click="onAdd()" :disabled="!allowToPostAdd") Tambahkan
                  button.px-4.btn-sm.btn.btn-primary(style="text-transform: none;" v-if="vehicle" @click="onEdit()" :disabled="!allowToPostEdit") Perbarui

    upload-modal( 
      v-if="selectPhoto" 
      title="Select an Image" 
      :placeholder="require('@/assets/img/car-placeholder-square.png')"
      :ratio="1.65"
      @selected="onSelectPhoto" 
      @close="selectPhoto = false" 
    )
    vehicle-type-modal(v-if="showTypeModal" @close="showTypeModal = false" @success="getTypes")
    vehicle-series-modal(v-if="showSeriesModal" @close="showSeriesModal = false" @success="getSeries" )
    confirmation-modal(
      
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="$emit('close');" 
        title="Buang Perubahan"
        confirm-text="Ya"
      
      )
        p.m-0
          | Apakah Anda yakin ingin membuang perubahan yang Anda buat?
</template>

<script>
import Camera from "vue-material-design-icons/Camera.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import Check from "vue-material-design-icons/Check.vue";

import UploadModal from "@/components/Matador/UploadModal.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import VehicleTypeModal from "./VehicleTypeModal.vue";
import VehicleSeriesModal from "./VehicleSeriesModal.vue";

import { get } from "lodash";
import {
  getVehicleType,
  getSeriesByVehicleType,
  postEditVehicle,
  postAddVehicle,
  getVehicleDetail,
  getDeviceEmpty,
} from "@/utils/api";
import Api from "@/utils/api";

import { nothing } from "immer";
export default {
  data() {
    return {
      unit_id: null,
      image: "",
      vehicle_name: "",
      vin: "",
      device_id: "",
      vehicle_type: null,
      series: null,
      wiper_width: null,
      optionDevices: [],
      optionTypes: [],
      optionSeries: [],
      selectPhoto: false,
      saving: false,
      showTypeModal: false,
      showSeriesModal: false,
      showCancelModal: false,
      created_by: this.$store.state.auth.user_id,
      updated_by: this.$store.state.auth.user_id,
    };
  },
  watch: {
    vehicle_type: {
      handler: function (newVal) {
        if (!this.vehicle) this.series = null;
        this.optionSeries = [];
        this.getSeries();
      },
    },
    vehicle: {
      handler: function (newVal) {
        const image_reader = new FileReader();
        if (newVal) {
          getVehicleDetail({ unit_id: newVal.unit_id }).then(
            ({ data: { result: vehicle } }) => {
              this.unit_id = vehicle.unit_id;
              !!this.vehicle_name
                ? (this.vehicle_name = vehicle.unit_id)
                : (this.vehicle_name = vehicle.vehicle_name);
              this.vin = vehicle.vin;
              this.wiper_width = vehicle.wiper_width;
              this.vehicle_type = get(vehicle, "unit_type.vehicle_type_id");
              this.series = get(vehicle, "unit_type.series");
              this.image = "";
              this.device_id = get(vehicle, "device_unit.device_id");
              if (get(vehicle, "device_unit.device_id"))
                setTimeout(() => {
                  this.optionDevices.push({
                    value: get(vehicle, "device_unit.device_id"),
                    text: get(vehicle, "device_unit.device.description"),
                  });
                }, 1750);
            }
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    Camera,
    Plus,
    Check,
    UploadModal,
    VehicleTypeModal,
    VehicleSeriesModal,
    ConfirmationModal,
  },
  props: ["close", "vehicle", "success"],
  computed: {
    postDataAdd() {
      const {
        unit_id,
        vehicle_name,
        device_id,
        vin,
        wiper_width,
        image,
        series,
        vehicle_type,
        created_by,
      } = this;
      const postDataAdd = {
        unit_id,
        vehicle_name,
        device_id,
        vin,
        wiper_width,
        image,
        series,
        created_by,
      };
      if (!!vehicle_type && this.optionTypes.length > 0)
        postDataAdd.type_name = this.optionTypes.filter(
          (item) => item.vehicle_type_id == vehicle_type
        )[0].name;
      return postDataAdd;
    },
    postDataEdit() {
      const {
        unit_id,
        vehicle_name,
        device_id,
        vin,
        wiper_width,
        image,
        series,
        vehicle_type,
        updated_by,
      } = this;
      const postDataEdit = {
        unit_id,
        vehicle_name,
        device_id,
        vin,
        wiper_width,
        image,
        series,
        updated_by,
      };
      if (!!vehicle_type && this.optionTypes.length > 0)
        postDataEdit.type_name = this.optionTypes.filter(
          (item) => item.vehicle_type_id == vehicle_type
        )[0].name;
      return postDataEdit;
    },
    allowToPostAdd() {
      const { vehicle_name, device_id, vin, series, type_name } =
        this.postDataAdd;
      return !!vehicle_name && !!device_id && !!vin && !!type_name && !!series;
    },
    allowToPostEdit() {
      const { vehicle_name, device_id, vin, series, type_name } =
        this.postDataEdit;
      return !!vehicle_name && !!device_id && !!vin && !!type_name && !!series;
    },
  },
  methods: {
    getSeries(payload) {
      Api.vehicle.collect_series(payload).then(({ data, status }) => {
        console.log(data);
      });

      getSeriesByVehicleType(this.vehicle_type).then(({ data: { result } }) => {
        this.optionSeries = result.map((item) => {
          item.value = item.series;
          item.text = `${item.series}`;
          return item;
        });
        if (!!payload) {
          this.series = this.optionSeries.filter(
            (item) => item.series === payload.series
          )[0].series;
        }
      });
    },
    getTypes(payload) {
      getVehicleType().then(({ data: { result } }) => {
        this.optionTypes = result.map((item) => {
          item.value = item.vehicle_type_id;
          item.text = item.name
            .split(" ")
            .map((str) => {
              return str[0].toUpperCase() + str.substring(1);
            })
            .join(" ");
          return item;
        });
        if (!!payload) {
          this.vehicle_type = this.optionTypes.filter(
            (item) => item.name === payload.name
          )[0].vehicle_type_id;
        }
      });
    },
    onSelectPhoto(base64) {
      this.image = base64;
      this.selectPhoto = false;
    },
    onAdd() {
      this.saving = true;
      const callback = postAddVehicle;
      // console.log(this.postDataAdd)
      callback(this.postDataAdd).then(({ data: { result, code, messgae } }) => {
        if (code === 200) {
          this.$emit("success", result);
          this.$toast.open("Successfully save vehicle data.");
          this.saving = false;
        } else {
          {
            this.$toast.error("Error " + code + ": " + messgae);
            this.saving = false;
          }
        }
      });
    },
    onEdit() {
      this.saving = true;
      // console.log(this.postDataEdit)
      const callback = postEditVehicle;
      callback(this.postDataEdit).then(
        ({ data: { result, code, message } }) => {
          if (code === 200) {
            this.$emit("success", result);
            this.$toast.open("Successfully change vehicle data.");
            this.saving = false;
          } else {
            this.$toast.error("Error " + code + ": " + message);
            this.saving = false;
          }
        }
      );
    },
    OnAddSeries(value) {
      localStorage.setItem("vehicle_type_id", value);
      this.vehicle_type = value;
      this.showSeriesModal = true;
    },
    onCancel() {
      this.showCancelModal = true;
    },
  },
  mounted() {
    this.getTypes();
    getDeviceEmpty({
      // type: 'empty_unit'
    }).then(({ data: { result } }) => {
      this.optionDevices = result.map((item) => {
        item.value = item.device_id;
        item.text = item.description;
        return item;
      });
    });
  },
};
</script>
